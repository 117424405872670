h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: bold;
  margin-bottom: 3px;
  margin-top: 3px;
}

h1 {
  font-size: 4rem;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.1rem;
}

h4 {
  font-size: 1.2rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.8rem;
}

p {
  font-size: 1rem;
}

.wager-text {
  font-size: 1.2rem !important;
}

.h1-companion {
  font-size: 1.8rem;
  margin-bottom: 5px;
  margin-top: 5px;
  font-weight: bold !important;
}

.h2-companion {
  font-size: 1rem;
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: 130% !important;
}

.h3-companion {
  font-size: 0.9rem;
}

.h4-companion {
  font-size: 1.4rem;
  margin-bottom: 3px;
  margin-top: 3px;
}

.odds-text {
  font-size: 0.9rem;

  margin-bottom: 2px;
  margin-top: 5px;
}

.prop-money-text {
  font-size: 1.2rem;
}

.details-text {
  font-size: 0.85rem;
}

.title-text {
  font-size: 1.05rem !important;
  margin-bottom: 0px;
  margin-top: 0px;
}

.small-details-text {
  font-size: 0.82rem;
}

.small-details-text-3 {
  font-size: 0.95rem;
}

.small-details-text-5 {
  font-size: 0.85em !important;
  margin: 1px;
}

.small-details-text-2 {
  font-size: 0.85rem;
}

.prop-details-text {
  font-size: 0.9rem;
  font: 0.9rem/0.5 var(--font-family-monospace);
}

.leaderboard-font {
  margin-bottom: 3px;
  margin-top: 3px;
  font-size: 1rem !important;
}

.big-wager-text {
  font-size: 1.05rem;
}

.big-wager-text2 {
  font-size: 1rem;
}

.h7 {
  font-size: 1rem;
  margin-bottom: 3px;
  margin-top: 3px;
}

.header-font {
  font-size: 4rem;
}

.navtext {
  font-size: 0.65rem;
}

.navtext2 {
  font-size: 0.7rem;
}

.lobbytext {
  font-size: 0.9rem !important;
}

.st {
  text-decoration: line-through;
}

.rules-page svg {
  margin-right: 8px;
}

.rules-page ul {
  margin-bottom: 28px;
}

.social-links {
  margin: 15px 15px;
}

.special-link {
  color: #969db7 !important;
}

.upsells-link {
  color: #969db7 !important;
}

.nav-link-nav {
  color: #88899f !important;
}

.prop-link {
  color: #d3d6df !important;
}

.upsell-link {
  color: #cfcfcf !important;
}

.social-links p {
  margin-top: 20px;
}

.jumbo-info-pages h3 {
  color: #ffbd5a;
}

.home-footer p {
  margin: 10px 0 15px;
}

.nav-footer a {
  font-size: 1.1rem;
  color: #4a4a4f !important;
  padding: 0 20px;
}

.nav-color {
  color: #9d9d9d;
}

.nav-lighter {
  color: #c4c4c4 !important;
}

.nav-love {
  color: #f5f5f5 !important;
}

.nav-light {
  color: #f0f0e6 !important;
}

.nav-darker {
  color: #9d9d9d !important;
}

.nav-color-green {
  color: #bad7cf;
}

.nav-color-dull {
  color: #7e7e7e;
}

.even-duller {
  color: #72727d !important;
}

.dullnewlon {
  color: #5b5b64;
}

.home-header {
  text-align: center;
}

@media (min-width: 768px) {
  .home-header {
    text-align: left;
  }
}

/*.jumbo-card.card {
  margin-bottom: 2.5rem;
}*/

.nav-footer {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.jumbo-card {
  min-width: 225px !important;
  max-width: 225px !important;
  background-color: #111111;
  border-radius: 5px;
  border-color: #111111;
  color: rgba(255, 255, 255, 1);
  border-width: 1px !important;
  margin-bottom: 15px !important;
  margin-right: 15px !important;
  /*box-shadow: 0 3px 15px rgb(0, 0, 0, 0.4);*/
}

.jumbo-card:hover {
  min-width: 225px !important;
  max-width: 225px !important;
  background-color: #151515 !important;
  border-color: #151515;
  color: rgba(255, 255, 255, 1);
  border-width: 1px !important;
  /*box-shadow: 0 3px 15px rgb(0, 0, 0, 0.4);*/
}

.card-header {
  background-color: #000000;
  border-radius: 15px 15px 0 0 !important;
}

.card-header-2 {
  background-color: #000000;
  border-radius: 15px 15px 15px 15px !important;
}

.card-body {
  padding: 9px 22px 5px 22px !important;
}

.card-body-mod {
  padding: 0px 22px 20px 22px !important;
}

.card-body-chat {
  padding: 9px 12px 10px 12px !important;
}

.game-lobby-img .card-img-top {
  border-radius: 0px 0px 0px 0px !important;
}

.card-img-top {
  border-radius: 0px 0px 0px 0px !important;
}

.jumbo-card-shop {
  min-width: 300px !important;
  max-width: 300px;
  border-radius: 15px;
  background-color: #000000;
  border-color: #32323a;
  border-width: 1px !important;
  color: rgba(255, 255, 255, 1);
  border-width: 1px !important;
  margin-bottom: 20px !important;
}

.jumbo-card-shop3 {
  min-width: 300px !important;
  max-width: 300px;
  border-radius: 15px;
  background-color: #000000;
  border-color: #000000;
  border-width: 1px !important;
  color: rgba(255, 255, 255, 1);
  border-width: 1px !important;
  margin-bottom: 00px !important;
}

.jumbo-card-shop-2 {
  min-width: 300px !important;
  border-radius: 15px;
  background-color: #000000;
  border-color: #000000;
  border-width: 1px !important;
  color: rgba(255, 255, 255, 1);
  border-width: 1px !important;
  margin-bottom: 20px !important;
}

.jumbo-card-text {
  color: #8b95ba !important;
}

.jumbo-card-title {
  color: white !important;
}

.content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content-amount {
  min-width: 110px;
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content-44 {
  min-width: 110px !important;
  max-width: 110px !important;
  line-height: 100%;
}

.content-2 {
  max-width: 120px;
  overflow: auto;
  white-space: nowrap;
}

.content-21 {
  min-width: 120px;
  max-width: 120px;
  overflow: auto;
  white-space: nowrap;
  text-overflow: auto;
}

.content-prop {
  min-width: 180px;
  max-width: 180px;
  overflow: auto;
  white-space: nowrap;
}

.column-small {
  column-width: 10px !important;
  min-width: 10px !important;
  max-width: 10px !important;
}

.column-med {
  column-width: 20px !important;
  min-width: 20px !important;
  max-width: 20px !important;
}

.content-prop2 {
  min-width: 170px !important;
  max-width: 170px !important;
  overflow: auto;
  white-space: nowrap;
}

.content-prop5 {
  min-width: 230px !important;
  max-width: 230px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content-4 {
  max-width: 90px;
  overflow: auto;

  text-overflow: ellipsis;
}

.content-3 {
  max-width: 220px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: top !important; /* Vertical center alignment */
  justify-content: left !important; /* Horizontal center alignment */
}

.content-game {
  max-width: 200px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.content-5 {
  max-width: 220px !important;
  white-space: initial;
}

.prop-content {
  max-width: 100%;
  min-height: 75px;
  max-height: 75px;
  text-overflow: ellipsis;
  overflow: auto;
}

.prop-content-fix {
  min-height: 10px !important;
  max-height: 10px !important;
  text-align: center;
}

.prop-content-hack-tr {
  min-height: 50px !important;
}

.prop-content-hack {
  min-height: 20px !important;
  max-height: 20px !important;
}

.prop-content-hack-2 {
  min-height: 210px !important;
  max-height: 210px !important;
}

.prop-content-hack-69 {
  min-height: 185px !important;
}

.prop-content-hack-3 {
  min-height: 40px !important;
  max-height: 40px !important;
}

.prop-content-hack-50 {
  min-width: 40px !important;
  max-width: 40px !important;
}

.text-align-center {
  text-align: center;
}

.flex-container {
  max-width: 100%;
  min-height: 120px !important;

  text-overflow: ellipsis;
  overflow: auto;
  display: flex !important;
  align-items: top !important; /* Vertical center alignment */
  justify-content: left !important; /* Horizontal center alignment */
}

.border {
  border: 5px solid #b22222;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.jumbo-card-shop-header {
  max-height: 75px;

  background-color: #000000;

  border-color: #000000;
}

.jumbo-card-deck {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  border-style: none;
}

.jumbo-login-button {
  background-color: #282436 !important;
  color: white !important;
  border-radius: 3.5px;
  border-style: none !important;
}

.text-white {
  color: white !important;
}

.scored-container {
  text-align: center;
  background-color: #111111;
  height: 100%;
  position: absolute;
  width: 100%;
}

.graded-container {
  text-align: center;
  background-color: #111111;
  height: 100%;
  position: absolute;
  width: 100%;
}

.bet-prop {
  text-align: center;
  background-color: rgba(32, 32, 32, 0.92);
  height: 100%;
  position: absolute;
  width: 100%;
}

.quiet-link {
  text-decoration: none !important;
  color: inherit !important;
}

.titlecenter {
  text-align: center;
}

.modal-center {
  text-align: center;
  display: inline-block;
}

.modal-content {
  background-color: #000000;
}

.modal-header {
  border-color: #000000;
}

.list-group-item {
  border-color: #495585;
  padding: 40px 8px 3px 10px;
}

.nav-jumbo {
  background-color: black;
  border-color: #323232 !important;
  margin: 0px !important;
  padding: 12px !important;
}

.jeopardy {
  font-variant: small-caps;
  letter-spacing: 1.1px !important;
  /*text-shadow: 2px 2px 1px black;*/
}

.nav-jumbo-link {
  padding: 0px 0px;
  margin: 0px 20px !important;
}

.nav-jumbo-link33 {
  padding: 0px 0px;
  margin: 0px 12px !important;
}

.nav-jumbo-link-2 {
  color: #aeaec4 !important;
}

.nav-jumbo-link-pink {
  padding: 0px 0px;
  margin: 0px 12px !important;
  color: #00fbd7 !important;
}

.nav-jumbo-link:active,
.nav-jumbo-link:focus,
.nav-jumbo-link:hover,
.nav-jumbo-link.active:hover,
.nav-jumbo-link.active:focus,
.nav-jumbo-link.active.dropdown-toggle {
}

.mx-auto {
  text-align: center !important;
}

.alert.alert-winners {
  background-color: #566599 !important;
}

.alert-custom-blue {
  background-color: #000000 !important;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px !important;
  border-style: solid !important;
}

.alert-custom-orange {
  background-color: #000000 !important;
  border-color: orange !important;
  border-width: 1px !important;
  border-style: solid !important;
}

.alert-custom {
  background-color: #000000 !important;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 1px !important;
  border-style: solid !important;
}

.alert-success {
  color: white;
  background-color: #5A5A5A;
  border-color: #5A5A5A;
}

.alert-custom2 {
  background-color: #000000;
  border-color: #282828 !important;
  border-width: 1px !important;
  border-style: solid !important;
  color: #fff;
  height: 80px !important;
}

.alert-custom3 {
  background-color: #212121;
  border-color: #212121 !important;
  border-width: 1px !important;
  border-style: solid !important;
}

.alert-custom4 {
  background-color: #000000;
  border-color: rgba(255, 255, 255, 0.4);
  border-width: 1px !important;
  border-style: solid !important;
}

.alert-success-mod {
  background-color: #000000;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 0.5px !important;
  border-style: solid !important;
}

.alert-custom4b {
  background-color: #000000;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 0.5px !important;
  border-style: solid !important;
}

.alert-custom4c {
  background-color: #212121;
  border-color: #212121;
  border-width: 0.5px !important;
  border-style: solid !important;
}

.alert-custom4a {
  background-color: #000000;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 0.5px !important;
  border-style: solid !important;
}

.alert-custom5 {
  background-color: #000000;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 0.5px !important;
  border-style: solid !important;
}

.alert-custom6 {
  background-color: #000000;
  border-color: rgba(255, 255, 255, 0.2);
  border-width: 0.5px !important;
  border-style: solid !important;
}

.announcement-custom {
  background-color: #000000;

  border-radius: 5px 5px 5px 5px !important;
  border-color: #28282e !important;
  border-style: solid !important;
  border-width: 1px !important;
}

.announcement-custom-2 {
  background-color: #000000;
  color: #e1e1ed;

  border-radius: 5px 5px 5px 5px !important;
  border-color: #28282e !important;
  border-style: solid !important;
  border-width: 1px !important;
}

.btn-primary {
  background-color: #1e90ff;
  color: gray;
  border-color: #1e90ff;
  border-radius: 15px;
  outline: none !important;
}

.btn.btn-outline-warning {
  padding: 12px !important;
  background-color: #1a1a1a !important;
  border-color: black !important;
}

.btn.btn-outline-warning.focus,
.btn.btn-outline-warning:focus {
  outline: none !important;
  background-color: #1a1a1a !important;
  border-color: black !important;
  box-shadow: none;
  outline: 0 !important;
  outline-offset: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.btn.btn-outline-warning:hover {
  outline: none !important;
  background-color: #1a1a1a !important;
  border-color: black !important;
  box-shadow: none;
  outline: 0 !important;
  outline-offset: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.btn.btn-outline-warning.active,
.btn.btn-outline-warning:active {
  outline: none !important;
  background-color: #1a1a1a !important;
  border-color: black !important;
  box-shadow: none;
  outline: 0 !important;
  outline-offset: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn.btn-outline-warning.active.focus,
.btn.btn-outline-warning.active:focus,
.btn.btn-outline-warning.active:hover,
.btn.btn-outline-warning:active.focus,
.btn.btn-outline-warning:active:focus,
.btn.btn-outline-warning:active:hover,
.btn.btn-outline-warning.dropdown-toggle,
.open .dropdown-toggle.btn.btn-outline-warning {
  outline: none !important;
  background-color: #1a1a1a !important;
  border-color: black !important;
  box-shadow: none;
  outline: 0 !important;
  outline-offset: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn.btn-outline-warning:disabled {
  color: rgba(255, 255, 255, 0.2) !important;
  background-color: #181818 !important;

  font-weight: bold;
}

.outline-info {
  color: #2890ff !important;
}

.warning-info {
  color: #ff7583 !important;
}

.btn.btn-outline-info {
  padding: 12px !important;
  background-color: #1a1a1a !important;
  border-color: black !important;
}

.btn.btn-outline-info:disabled {
  background-color: #181818 !important;
}

.btn.btn-outline-info.focus,
.btn.btn-outline-info:focus {
  outline: none !important;
  background-color: #1a1a1a !important;
  border-color: black !important;
  box-shadow: none;
  outline: 0 !important;
  outline-offset: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.btn.btn-outline-info:hover {
  outline: none !important;
  background-color: #1a1a1a !important;
  border-color: black !important;
  box-shadow: none;
  outline: 0 !important;
  outline-offset: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.btn.btn-outline-info.active,
.btn.btn-outline-info:active {
  outline: none !important;
  background-color: #1a1a1a !important;
  border-color: black !important;
  box-shadow: none;
  outline: 0 !important;
  outline-offset: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.btn.btn-outline-info.active.focus,
.btn.btn-outline-info.active:focus,
.btn.btn-outline-info.active:hover,
.btn.btn-outline-info:active.focus,
.btn.btn-outline-info:active:focus,
.btn.btn-outline-info:active:hover {
  outline: none !important;
  background-color: #1a1a1a !important;
  border-color: black !important;
  box-shadow: none;
  outline: 0 !important;
  outline-offset: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.activity-cell-lower {
  background-color: #0b1b18 !important;
  border-color: #0b1b18 !important;
  height: 10px !important;
  color: #5e8e80 !important;
}

.activity-cell-med {
  background-color: #16624f !important;
  border-color: #16624f !important;
  height: 10px !important;
  color: #eaf0ee !important;
}

.activity-cell-low {
  background-color: #1a322c !important;
  border-color: #1a322c !important;
  color: #87a99d !important;
  height: 10px !important;
}

.activity-cell-high {
  background-color: #0ae2a1 !important;
  border-color: #0ae2a1 !important;
  color: black !important;
  font-weight: bold;
  height: 10px !important;
}

.binary {
  color: #f5f5f5;
}

.prop-color {
  color: #3a446c;
}

.binary2 {
  border-radius: 20px;
  color: #e5c567;
}

.black {
  color: black !important;
}

.ivory {
  color: #aab2c8;
}

.wager {
  color: #00fbd7;
}

.wager2 {
  color: #00fbd7;
}

.gold-color {
  color: #f8db08 !important;
}

.wager-dark {
  color: #b28100;
}

.select-color {
  color: #f037a5 !important;
}

.mod {
  color: #f037a5;
}

.wager-prop {
  color: #f037a5;
}

mark {
  background-color: #f5bd1f;
  color: black;
}

.risk {
  color: white;
}

.float-right {
  float: right;
}

.vertical-center {
  vertical-align: middle;
  display: inline-block;
}

.fullprice {
  text-decoration: line-through;
}

.host-game-svg {
  margin-right: 5px;
}

.badge-timer {
  padding: 7px !important;
  font-size: 0.9rem !important;
  background-color: #000000;
  border-color: black !important;
  color: red !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 3px !important;
}

.badge-timer-small {
  font-size: 0.75rem !important;
  background-color: none;
  border-color: rgba(255, 255, 255, 0) !important;
  color: red !important;
  vertical-align: bottom !important;
}

.badge-timer-orange {
  font-size: 0.75rem !important;
  background-color: none;
  border-color: rgba(255, 255, 255, 0) !important;
  color: orange !important;
  vertical-align: bottom !important;
}

.badge-timer-small-delay {
  font-size: 0.9rem !important;
  background-color: none;
  border-color: rgba(255, 255, 255, 0) !important;
  color: #72727d !important;
  vertical-align: bottom !important;
}

.badge-timer-small-delay2 {
  font-size: 0.75rem !important;
  background-color: none;
  border-color: rgba(255, 255, 255, 0) !important;
  color: #7e7e7e !important;
  vertical-align: bottom !important;
}

.badge-tertiary {
  border-radius: 20px !important;
  font-size: 18px !important;
  letter-spacing: 1.5px !important;
  padding: 7px;
  color: #aeaec4 !important;
  border-color: #323232 !important;
  background-color: #000000 !important;
  border-width: 1px !important;
  border-style: solid !important;
}

.badge-red-small {
  border-radius: 5px !important;
  font-size: 12px !important;
  padding: 2px;
  color: white !important;
  border-color: #000000 !important;
  background-color: #000000 !important;
  border-width: 1px !important;
  border-style: solid !important;
}

.smallest-host-text {
  font-size: 0.7rem !important;
}

.small-host-text {
  font-size: 0.75rem !important;
}

.small-host-text2 {
  font-size: 0.85rem !important;
}

.badge-red-small-2 {
  font-size: 0.75rem !important;
  color: rgba(255, 255, 255, 0.4) !important;
  border-color: rgba(255, 255, 255, 0) !important;
  border-radius: 39px !important;
  background-color: black !important;
  border-width: 1px !important;
  border-style: solid !important;
}

.badge-tertiary-small {
  border-radius: 20px !important;
  font-size: 10px !important;
  padding: 5px;
  color: #494950 !important;
  border-color: #000000 !important;
  background-color: #000000 !important;
  border-width: 1px !important;
  border-style: solid !important;
}

.badge-tertiary-big {
  border-radius: 20px !important;
  font-size: 22px !important;
  padding: 5px;
  color: #494950 !important;
  border-color: #000000 !important;
  background-color: #000000 !important;
  border-width: 1px !important;
  border-style: solid !important;
}

.badge-blue {
  color: white;
  font-size: 12px !important;
  border: 1px solid #38192a;
  background-color: #38192a;
}

.badge-green {
  color: white;
  font-size: 12px !important;
  border: 1px solid #062f2f;
  background-color: #062f2f;
}

.badge-black {
  color: #f037a5 !important;
  font-size: 11px !important;
  border: 1px solid #062f2f;
  border-color: #000000 !important;
  background-color: #000000 !important;
}

.mod-purple {
  color: #ed27ed !important;
}

.badge-black-mod {
  color: #ed27ed !important;
  font-size: 11px !important;
  border: 1px solid #062f2f;
  border-color: #000000 !important;
  background-color: #000000 !important;
}

.badge-black-host-tag {
  color: #a0a0a0 !important;
  font-size: 11px !important;
  border: 1px solid #062f2f;
  border-color: #181818 !important;
  background-color: #000000 !important;
}

.badge-black-big {
  color: #f8f8f8 !important;
  font-size: 14px !important;
  border: 1px solid #062f2f;
  border-color: #252529 !important;
  background-color: #000000;
  padding: 10px !important;
}

.badge-tertiary-pink {
  border-radius: 20px !important;
  font-size: 16px !important;
  letter-spacing: 1.5px !important;
  padding: 7px;
  color: #aeaec4 !important;
  border-color: #db1212 !important;
  background-color: #db1212 !important;
  border-width: 1px !important;
  border-style: solid !important;
}

.progress {
  height: 15px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.progress-bar {
  background-color: #32333a;
  border-color: #32333a;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: white !important;
  font-weight: bold !important;
}

.progress-bar-lobby {
  background-color: rgba(255, 255, 255, 0.1);
  height: 25px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

#progress-bar.warning {
  background-color: red;
}

.bg-success {
  background-color: #31f6bb !important;
}
.bg-warning {
  background-color: #057251 !important;
}

.progress-bar-red {
  background-color: #e74c3c;
  border-color: #e74c3c;
  height: 20px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.game-lobby-prompt-btn {
  color: white;
  background-color: #a346a2;
  border: none;
}

.hard-prop {
  color: #ff003a !important;
}

.hard-prop-faded {
  color: #853b41 !important;
}

.med-prop {
  color: #df3367 !important;
}

.med-prop-faded {
  color: #7a5e46 !important;
}

.low-prop {
  color: #ac2e54 !important;
}

.low-prop-faded {
  color: #7a7146 !important;
}

.basic-prop {
  color: #803047 !important;
}

.basic-prop-faded {
  color: #65764a !important;
}

.spinner-pink {
  color: #00fbd7 !important;
}

.spinner-pink-2 {
  color: #00fbd7 !important;
}

.spinner-pink-3 {
  color: #8a2543 !important;
}

.spinner-pink-4 {
  color: #662639 !important;
}

.spinner-pink {
  color: #00fbd7 !important;
}

.spinner-pink-2 {
  color: #00fbd7 !important;
}

.spinner-pink-3 {
  color: #8a2543 !important;
}

.spinner-pink-4 {
  color: #662639 !important;
}

.register-button {
  font-sz: 12 !important;
  font-weight: bold !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: #f037a5 !important;
  border-radius: 5px !important;
  color: #f037a5 !important;
  padding: 10px !important;
}

.register-button,
.register-button:hover,
.register-button:focus,
.register-button:active,
.register-button.active,
.open .dropdown-toggle.register-button {
  font-sz: 12 !important;
  font-weight: bold !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: #f037a5;
  border-radius: 5px !important;
  color: #f037a5 !important;
  padding: 10px !important;
}

.open .dropdown-toggle.register-button {
  font-sz: 12 !important;
  font-weight: bold !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: #f037a5 !important;
  border-radius: 5px !important;
  color: #f037a5 !important;
  padding: 10px !important;
}

.register-button-button {
  font-sz: 12 !important;
  font-weight: bold !important;
  color: black !important;
}

.register-button-cash {
  font-sz: 12 !important;
  font-weight: bold !important;
  background-color: #31f6bb !important;
  border-color: #31f6bb !important;
  color: black !important;
  border-radius: 39px !important;
  padding: 10px !important;
}

.register-button-cash:hover,
.register-button-cash:focus,
.register-button-cash:active,
.register-button-cash.active,
.open .dropdown-toggle.register-button-cash {
  font-sz: 12 !important;
  font-weight: bold !important;
  background-color: #31f6bb !important;
  border-color: #31f6bb !important;
  color: black !important;
  border-radius: 39px !important;
  padding: 10px !important;
}

.open .dropdown-toggle.register-button-cash {
  font-sz: 12 !important;
  font-weight: bold !important;
  background-color: #31f6bb !important;
  border-color: #31f6bb !important;
  color: black !important;
  border-radius: 39px !important;
  padding: 10px !important;
}

.register-button2 {
  font-sz: 12 !important;
  font-weight: bold !important;
  background-color: black !important;
  border-color: #00fbd7 !important;
  color: #00fbd7 !important;
  border-radius: 5px !important;
  padding: 10px !important;
}

.register-button2:hover,
.register-button2:focus,
.register-button2:active,
.register-button2.active,
.open .dropdown-toggle.register-button2 {
  font-sz: 12 !important;
  font-weight: bold !important;
  background-color: #1a1a1a !important;
  border-color: #00fbd7 !important;
  color: #00fbd7 !important;
  border-radius: 5px !important;
  padding: 10px !important;
}

.open .dropdown-toggle.register-button2 {
  font-sz: 12 !important;
  font-weight: bold !important;
  background-color: #1a1a1a !important;
  border-color: #00fbd7 !important;
  color: #00fbd7 !important;
  border-radius: 5px !important;
  padding: 10px !important;
}

.register-button3 {
  font-sz: 12 !important;
  font-weight: bold !important;
  background-color: black !important;

  border-color: rgba(255, 255, 255, 0.2) !important;
  color: white !important;
  border-radius: 5px !important;
  padding: 10px !important;
}

.register-button3:hover,
.register-button3:focus,
.register-button3:active,
.register-button3.active,
.open .dropdown-toggle.register-button3 {
  font-sz: 12 !important;
  font-weight: bold !important;
  background-color: black !important;
  border-color: black !important;
  color: white !important;
  border-radius: 5px !important;
  padding: 10px !important;
}

.open .dropdown-toggle.register-button3 {
  font-sz: 12 !important;
  font-weight: bold !important;
  background-color: black !important;
  border-color: black !important;
  color: white !important;
  border-radius: 5px !important;
  padding: 10px !important;
}

.share-button-throne3 {
  font-sz: 12 !important;
  padding: 10px !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: #0ae2a1 !important;
  border-radius: 5px !important;
  color: #0ae2a1 !important;
}

.share-button-throne3,
.share-button-throne3:hover,
.share-button-throne3:focus,
.share-button-throne3:active,
.share-button-throne3.active,
.open .dropdown-toggle.share-button-throne3 {
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: #0ae2a1;
  border-radius: 5px !important;
  color: #0ae2a1 !important;
}

.open .dropdown-toggle.share-button-throne3 {
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: #0ae2a1 !important;
  border-radius: 5px !important;
  color: #0ae2a1 !important;
}

.share-button-throne {
  font-sz: 10 !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: #0ae2a1 !important;
  border-radius: 5px !important;
  color: #0ae2a1 !important;
  padding: 0px !important;
}

.share-button-throne,
.share-button-throne:hover,
.share-button-throne:focus,
.share-button-throne:active,
.share-button-throne.active,
.open .dropdown-toggle.share-button-throne {
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: #0ae2a1;
  border-radius: 5px !important;
  color: #0ae2a1 !important;
}

.open .dropdown-toggle.share-button-throne {
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: #0ae2a1 !important;
  border-radius: 5px !important;
  color: #0ae2a1 !important;
}

.share-button-throne2 {
  font-sz: 9 !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: #f037a5 !important;
  border-radius: 5px !important;
  color: #f037a5 !important;
  padding: 6px !important;
}

.share-button-throne2,
.share-button-throne2:hover,
.share-button-throne2:focus,
.share-button-throne2:active,
.share-button-throne2.active,
.open .dropdown-toggle.share-button-throne2 {
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: #f037a5 !important;
  border-radius: 5px !important;
  color: #f037a5 !important;
}

.open .dropdown-toggle.share-button-throne2 {
  font-sz: 10 !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: #ffbf17 !important;
  border-radius: 5px !important;
  color: #ffbf17 !important;
}

.register-button-2 {
  font-sz: 12 !important;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: #ffbf17 !important;
  border-radius: 39px !important;
  color: black !important;
  padding: 10px;
}

.register-button-2:hover,
.register-button-2:focus,
.register-button-2:active,
.register-button-2.active,
.open .dropdown-toggle.register-button-2 {
  font-sz: 12 !important;
  font-weight: bold;
  background-color: #ffbf17;
}

.open .dropdown-toggle.register-button-2 {
  font-sz: 12 !important;
  font-weight: bold;
  background-color: #ffbf17;
}

.bet-button {
  font-sz: 12 !important;
  font-weight: bold;
  background-color: black;
  border-color: #28282e !important;
  border-radius: 5px !important;
  color: #ff24ac !important;
  padding: 8px;
  outline: none !important;
  box-shadow: none !important;
}

.bet-button:hover {
  background-color: #1b1e28 !important;
  color: #6f6f7f !important;
  border-color: #3c3c4a !important;
  outline: none !important;
  box-shadow: none !important;
}

.bet-button:focus,
.bet-button:active,
.bet-button.active,
.open .dropdown-toggle.bet-button {
  background-color: #1b1e28 !important;
  color: #6f6f7f !important;
  border-color: #3c3c4a !important;
  outline: none !important;
  box-shadow: none !important;
}

.open .dropdown-toggle.bet-button {
  background-color: #1b1e28 !important;
  color: #6f6f7f !important;
  border-color: #3c3c4a !important;
  outline: none !important;
  box-shadow: none !important;
}

.pink-button {
  padding: 10px !important;
  font-sz: 12 !important;
  font-weight: bold;
  background-color: #181818 !important;
  border-color: #ffbf17 !important;
  border-radius: 10px !important;
  color: #ffbf17 !important;
  outline: none !important;
  box-shadow: none !important;
}

.pink-button:hover {
  background-color: #a97f07 !important;
  color: #6f6f7f !important;
  border-color: #a97f07 !important;
  outline: none !important;
  box-shadow: none !important;
}

.text-gray {
  color: #6f6f7f !important;
}

.text-black {
  color: black !important;
}

.pink-button:focus,
.pink-button:active,
.pink-button.active,
.open .dropdown-toggle.pink-button {
  background-color: #7a6013 !important;
  color: black !important;
  border-color: #7a6013 !important;
  outline: none !important;
  box-shadow: none !important;
}

.open .dropdown-toggle.pink-button {
  background-color: #7a6013 !important;
  color: #6f6f7f !important;
  border-color: #7a6013 !important;
  outline: none !important;
  box-shadow: none !important;
}

.pink-bet-button2 {
  padding: 10px !important;
  font-size: 16px !important;
  font-weight: bold;
  background-color: #00fbd7 !important;
  border-color: rgba(255, 255, 255, 0) !important;
  border-radius: 0px !important;
  color: black !important;
  outline: none !important;
  box-shadow: none !important;
}

.pink-bet-button2:hover {
  background-color: #00fbd7 !important;
  color: rgba(255, 255, 255, 0.2) !important;
  outline: none !important;
  box-shadow: none !important;
}

.pink-bet-button2:focus,
.pink-bet-button2:active,
.pink-bet-button2.active,
.open .dropdown-toggle.pink-bet-button2 {
  background-color: #00fbd7 !important;
  color: rgba(255, 255, 255, 0.2) !important;
  outline: none !important;
  box-shadow: none !important;
}

.open .dropdown-toggle.pink-bet-button2 {
  background-color: #00fbd7 !important;
  color: rgba(255, 255, 255, 0.2) !important;
}

.pink-bet-button {
  padding: 16px !important;
  font-size: 16px !important;
  font-weight: bold;
  background-color: #00fbd7 !important;
  border-color: rgba(255, 255, 255, 0) !important;
  border-radius: 0px !important;
  color: black !important;
  outline: none !important;
  box-shadow: none !important;
}

.pink-bet-button:hover {
  background-color: #15544d !important;
  color: rgba(255, 255, 255, 0.2) !important;
  outline: none !important;
  box-shadow: none !important;
}

.pink-bet-button:focus,
.pink-bet-button:active,
.pink-bet-button.active,
.open .dropdown-toggle.pink-bet-button {
  background-color: #15544d !important;
  color: rgba(255, 255, 255, 0.2) !important;
  outline: none !important;
  box-shadow: none !important;
}

.open .dropdown-toggle.pink-bet-button {
  background-color: #15544d !important;
  color: rgba(255, 255, 255, 0.2) !important;
}

.yellow-bet-button {
  padding: 20px !important;
  font-size: 16px !important;
  font-weight: bold;
  color: white !important;
  border-radius: 0px !important;
  background-color: #d7272c !important;
  border-color: #d7272c !important;
}

.yellow-bet-button:focus,
.yellow-bet-button:active,
.yellow-bet-button.active,
.open .dropdown-toggle.yellow-bet-button {
  background-color: #4b1d1e !important;
  color: rgba(255, 255, 255, 0.3) !important;
  border-color: #282828 !important;
  outline: none !important;
  box-shadow: none !important;
}

.open .dropdown-toggle.yellow-bet-button {
  background-color: #4b1d1e !important;
  color: rgba(255, 255, 255, 0.3) !important;
  border-color: #282828 !important;
  outline: none !important;
  box-shadow: none !important;
}

.color-black {
  color: black !important;
}

.btn-dark:focus,
.btn-dark:active,
.btn-dark.active,
.open .dropdown-toggle.btn-dark {
  background-color: #1a1a1a !important;
  color: #00fbd7 !important;
  border-color: black !important;
  font-weight: bold !important;
  border-width: 1px !important;
  box-shadow: none;
  border-radius: 3px;
  outline: none !important;
  box-shadow: none !important;
}

.btn-dark {
  background-color: #1a1a1a !important;
  color: #00fbd7 !important;
  border-color: black !important;
  border-width: 1px !important;
  box-shadow: none !important;
  width: 70px !important;
  font-size: 0.7rem !important;
  border-radius: 3px;
  outline: none !important;
}

.btn-light {
  background-color: #1a1a1a !important;
  border-color: black !important;
  border-radius: 3px;
  font-size: 0.7rem !important;
  color: #72727d !important;
  border-width: 1px !important;
  width: 70px !important;
  outline: none !important;
  box-shadow: none !important;
}

.btn-light:focus {
  background-color: #222222 !important;
  font-weight: bold !important;
  color: #c4c4c4 !important;
  border-color: black !important;
  border-radius: 3px;
  border-width: 1px !important;
  outline: none !important;
  box-shadow: none !important;
}

.btn-light:active,
.btn-light.active,
.open .dropdown-toggle.btn-light {
  background-color: #222222 !important;
  font-weight: bold !important;
  color: #c4c4c4 !important;
  border-color: black !important;
  border-radius: 3px;
  border-width: 1px !important;
  outline: none !important;

  box-shadow: none !important;
}

.td-negative {
  color: #e74c3c !important;
}

.select-color {
  color: #d700fb !important;
}

.td-no {
  color: #ff285c !important;
  font-weight: bold;
}

.td-positive {
  color: #00fbd7 !important;
}

.td-yass {
  color: #2e9d78 !important;
}

.td-promo {
  color: #00fbd7 !important;
  font-weight: bold;
}

.td-positive {
  color: #00fbd7 !important;
}

.share-button {
  color: #f137a6 !important;
}

.buy-icon {
  color: #cce5ff !important;
}

.insights-button {
  color: #00cd8f !important;
}

.share-button-59 {
  color: #f51d5e !important;
}

.love-button {
  color: #f137a6 !important;
}

.love-button-tr {
  color: #f8d7ea !important;
}

.star-opacity {
  color: rgba(255, 255, 255, 0.2);
}

.star-opacity-100 {
  color: rgba(255, 255, 255, 0) !important;
}

.star-opacity2 {
  color: #9d9d9d !important;
}

.star-opacity3 {
  color: #7e7e7e !important;
}

.star-opacity5 {
  color: rgba(255, 255, 255, 0.75);
}

.player-opacity {
  opacity: 50%;
}

.details-button {
  font-sz: 12 !important;
  font-weight: bold;
  padding: 3px !important;
  background-color: #181818 !important;
  border-color: #28282e !important;
  border-radius: 5px !important;
  color: #ff004f;
}

.details-button:hover {
  background-color: #202434 !important;
  border-color: #202434 !important;
  color: #ff004f !important;
}

.details-button:focus,
.details-button:active,
.details-button.active,
.open .dropdown-toggle.details-button {
  background-color: #202434 !important;
  border-color: #202434 !important;
  color: #ff004f !important;
}

.open .dropdown-toggle.details-button {
  background-color: #202434 !important;
  border-color: #202434 !important;
  color: #ff004f !important;
}

.share-button-3 {
  padding: 8px !important;
  font-size: 16px !important;
  background-color: #00a979 !important;
  font-weight: bold !important;
  border-color: #93dcc9 !important;
  border-radius: 10px !important;
  color: #f8f8f8 !important;
}

.share-button-3:hover {
}

.share-button-3:focus,
.share-button-3:active,
.share-button-3.active,
.open .dropdown-toggle.share-button-3 {
}

.open .dropdown-toggle.share-button-3 {
}

.share-button-2 {
  padding: 10px !important;
  font-size: 16px !important;
  border-width: 0px !important;
  border-style: none !important;
  background-color: #d20946 !important;
  font-weight: bold !important;
  border-color: #d20946 !important;
  border-radius: 10px !important;
  color: white !important;
}

.share-button-2:hover {
}

.share-button-2:focus,
.share-button-2:active,
.share-button-2.active,
.open .dropdown-toggle.share-button-2 {
}

.open .dropdown-toggle.share-button-2 {
}

.share-win {
  padding: 8px !important;
  font-size: 14px !important;
  border-width: 1px !important;
  background-color: rgba(32, 32, 32, 0.95);
  font-weight: bold !important;
  border-color: #0ae2a1 !important;
  border-radius: 5px !important;
  color: #0ae2a1 !important;
}

.share-win:hover {
}

.share-win:focus,
.share-win:active,
.share-win.active,
.open .dropdown-toggle.share-win {
}

.open .dropdown-toggle.share-win {
}

.share-neutral {
  padding: 8px !important;
  font-size: 14px !important;
  border-width: 1px !important;
  background-color: #0ae2a1 !important;
  font-weight: bold !important;
  border-color: #0ae2a1 !important;
  border-radius: 5px !important;
  color: black !important;
}

.share-neutral:hover {
}

.share-neutral:focus,
.share-neutral:active,
.share-neutral.active,
.open .dropdown-toggle.share-neutral {
}

.open .dropdown-toggle.share-neutral {
}

.share-lose {
  padding: 8px !important;
  font-size: 14px !important;
  border-width: 1px !important;
  background-color: rgba(32, 32, 32, 0.95);
  font-weight: bold !important;
  border-color: #e74c3c !important;
  border-radius: 5px !important;
  color: #e74c3c !important;
}

.share-lose:hover {
}

.share-lose:focus,
.share-lose:active,
.share-lose.active,
.open .dropdown-toggle.share-lose {
}

.open .dropdown-toggle.share-lose {
}

.share-lose-neutral {
  padding: 8px !important;
  font-size: 14px !important;
  border-width: 0px !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
  font-weight: bold !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
  border-radius: 5px !important;
  color: rgba(255, 255, 255, 0.15) !important;
}

.share-lose-neutral:hover {
}

.share-neutral:focus,
.share-neutral:active,
.share-neutral.active,
.open .dropdown-toggle.share-neutral {
}

.logout-button9 {
  font-sz: 10 !important;
  background-color: #292929 !important;
  border-width: 1px !important;
  border-color: #292929 !important;
  border-radius: 39px !important;
  color: rgba(255, 255, 255, 0.5) !important;
}

.logout-button9:hover {
  background-color: #202434 !important;
  color: white !important;
}

.logout-button9:focus,
.logout-button9:active,
.logout-button9.active,
.open .dropdown-toggle.logout-button9 {
  background-color: #202434 !important;
  color: white !important;
}

.open .dropdown-toggle.logout-button9 {
  background-color: #202434 !important;
  color: white !important;
}

.logout-button44 {
  font-sz: 10 !important;
  padding: 10px !important;
  background-color: #212121 !important;
  border-color: #212121 !important;
  border-radius: 39px !important;
  color: rgba(255, 255, 255, 0.5) !important;
}

.logout-button44:hover {
  background-color: #202434 !important;
  color: white !important;
}

.logout-button44:focus,
.logout-button44:active,
.logout-button44.active,
.open .dropdown-toggle.logout-button44 {
  background-color: #202434 !important;
  color: white !important;
}

.open .dropdown-toggle.logout-button44 {
  background-color: #202434 !important;
  color: white !important;
}

.logout-button-s {
  padding: 0px 16px 3px 16px !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: rgba(255, 255, 255, 0) !important;
}

.logout-button-s:hover {
  padding: 0px 16px 3px 16px !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: rgba(255, 255, 255, 0) !important;
}

.logout-button-s:focus,
.logout-button-s:active,
.logout-button-s.active,
.open .dropdown-toggle.logout-button-s {
  padding: 0px 16px 3px 16px !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: rgba(255, 255, 255, 0) !important;
}

.open .dropdown-toggle.logout-button-s {
  padding: 0px 16px 3px 16px !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: rgba(255, 255, 255, 0) !important;
}

.logout-button-circle {
  padding: 1px 0px 0 0 !important;
  width: 30px !important;
  height: 30px !important;
  background-color: #212121 !important;
  border-color: #212121 !important;
  border-radius: 39px !important;
}

.logout-button-green {
  font-sz: 12 !important;
  color: black !important;
  font-weight: bold !important;
  padding: 0px 12px 4px 12px !important;
  background-color: #00fbd7 !important;
  border-color: #00fbd7 !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 39px !important;
}

.logout-button-green:hover {
  font-sz: 12 !important;
  color: black !important;
  padding: 0px 12px 4px 12px !important;
  background-color: #00fbd7 !important;
  border-color: #00fbd7 !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 39px !important;
}

.logout-button-green:focus,
.logout-button-green:active,
.logout-button-green.active,
.open .dropdown-toggle.logout-button-green {
  font-sz: 12 !important;
  color: black !important;
  padding: 0px 12px 4px 12px !important;
  background-color: #00fbd7 !important;
  border-color: #00fbd7 !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 39px !important;
}

.open .dropdown-toggle.logout-button-green {
  font-sz: 12 !important;
  color: black !important;
  padding: 0px 12px 4px 12px !important;
  background-color: #00fbd7 !important;
  border-color: #00fbd7 !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 39px !important;
}

.logout-button-p {
  font-sz: 12 !important;
  color: #72727d !important;
  padding: 0px 12px 4px 12px !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: rgba(255, 255, 255, 0.15) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 39px !important;
}

.logout-button-p:hover {
  font-sz: 12 !important;
  padding: 0px 12px 4px 12px !important;
  background-color: #00fbd7 !important;
  border-color: rgba(255, 255, 255, 0.15) !important;
  border-radius: 39px !important;
  color: black !important;
}

.logout-button-p:focus,
.logout-button-p:active,
.logout-button-p.active,
.open .dropdown-toggle.logout-button-p {
  font-sz: 12 !important;
  padding: 0px 12px 4px 12px !important;
  background-color: #00fbd7 !important;
  border-color: rgba(255, 255, 255, 0.15) !important;

  border-radius: 39px !important;
  color: black !important;
}

.open .dropdown-toggle.logout-button-p {
  font-sz: 12 !important;
  padding: 0px 12px 4px 12px !important;
  background-color: #00fbd7 !important;
  border-color: rgba(255, 255, 255, 0.15) !important;
  border-radius: 39px !important;
  color: black !important;
}

.logout-button-i {
  font-sz: 12 !important;
  padding: 0px 12px 4px 12px !important;
  background-color: #151515 !important;
  border-color: rgba(255, 255, 255, 0.15) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 39px !important;
}

.logout-button-i:hover {
  font-sz: 12 !important;
  padding: 0px 12px 4px 12px !important;
  background-color: #203432 !important;
  border-color: rgba(255, 255, 255, 0.15) !important;
  border-radius: 39px !important;
  color: black !important;
}

.logout-button-i:focus,
.logout-button-i:active,
.logout-button-i.active,
.open .dropdown-toggle.logout-button-i {
  font-sz: 12 !important;
  padding: 0px 12px 4px 12px !important;
  background-color: #203432 !important;
  border-color: rgba(255, 255, 255, 0.15) !important;
  border-radius: 39px !important;
  color: black !important;
}

.open .dropdown-toggle.logout-button-i {
  font-sz: 12 !important;
  padding: 0px 12px 4px 12px !important;
  background-color: #203432 !important;
  border-color: rgba(255, 255, 255, 0.15) !important;
  border-radius: 39px !important;
  color: black !important;
}

.logout-button-a {
  font-sz: 10 !important;
  padding: 0px 20px 3px 20px !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: rgba(255, 255, 255, 0.18) !important;
  border-radius: 39px !important;
}

.logout-button-a:hover {
  font-sz: 10 !important;
  padding: 0px 20px 3px 20px !important;
  background-color: #151515 !important;
  border-color: rgba(255, 255, 255, 0.18) !important;
  border-radius: 39px !important;
}

.logout-button-a:focus,
.logout-button-a:active,
.logout-button-a.active,
.open .dropdown-toggle.logout-button-a {
  font-sz: 10 !important;
  padding: 0px 20px 3px 20px !important;
  background-color: #151515 !important;
  border-color: rgba(255, 255, 255, 0.18) !important;
  border-radius: 39px !important;
}

.open .dropdown-toggle.logout-button-a {
  font-sz: 10 !important;
  padding: 0px 20px 3px 20px !important;
  background-color: #151515 !important;
  border-color: rgba(255, 255, 255, 0.18) !important;
  border-radius: 39px !important;
}

.logout-button-z {
  font-sz: 10 !important;
  padding: 0px 20px 3px 20px !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: rgba(255, 255, 255, 0.18) !important;
  border-radius: 39px !important;
}

.logout-button-z:hover {
  font-sz: 10 !important;
  padding: 0px 20px 3px 20px !important;
  background-color: #151515 !important;
  border-color: #212121 !important;
  border-radius: 39px !important;
}

.logout-button-z:focus,
.logout-button-z:active,
.logout-button-z.active,
.open .dropdown-toggle.logout-button-z {
  font-sz: 10 !important;
  padding: 0px 20px 3px 20px !important;
  background-color: #151515 !important;
  border-color: #212121 !important;
  border-radius: 39px !important;
}

.open .dropdown-toggle.logout-button-z {
  font-sz: 10 !important;
  padding: 0px 20px 3px 20px !important;
  background-color: #151515 !important;
  border-color: #212121 !important;
  border-radius: 39px !important;
}

.logout-button-y {
  font-sz: 10 !important;
  padding: 0px 20px 3px 20px !important;
  background-color: #212121 !important;
  border-color: #212121 !important;
  border-radius: 39px !important;
}

.logout-button-y:hover {
  font-sz: 10 !important;
  padding: 0px 20px 3px 20px !important;
  background-color: #212121 !important;
  border-color: #212121 !important;
  border-radius: 39px !important;
}

.logout-button-y:focus,
.logout-button-y:active,
.logout-button-y.active,
.open .dropdown-toggle.logout-button-y {
  font-sz: 10 !important;
  padding: 0px 20px 3px 20px !important;
  background-color: #212121 !important;
  border-color: #212121 !important;
  border-radius: 39px !important;
}

.open .dropdown-toggle.logout-button-y {
  font-sz: 10 !important;
  padding: 0px 20px 3px 20px !important;
  background-color: #212121 !important;
  border-color: #212121 !important;
  border-radius: 39px !important;
}

.logout-button0 {
  font-sz: 10 !important;
  padding: 10px !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: rgba(255, 255, 255, 0.2) !important;
  border-radius: 39px !important;
  color: rgba(255, 255, 255, 0.5) !important;
}

.logout-button0:hover {
  background-color: #202434 !important;
  color: white !important;
}

.logout-button0:focus,
.logout-button0:active,
.logout-button0.active,
.open .dropdown-toggle.logout-button0 {
  background-color: #202434 !important;
  color: white !important;
}

.open .dropdown-toggle.logout-button0 {
  background-color: #202434 !important;
  color: white !important;
}

.logout-button {
  font-sz: 10 !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: rgba(255, 255, 255, 0.2) !important;
  border-radius: 5px !important;
  color: rgba(255, 255, 255, 0.5) !important;
}

.logout-button:hover {
  background-color: #202434 !important;
  color: white !important;
}

.logout-button:focus,
.logout-button:active,
.logout-button.active,
.open .dropdown-toggle.logout-button {
  background-color: #202434 !important;
  color: white !important;
}

.open .dropdown-toggle.logout-button {
  background-color: #202434 !important;
  color: white !important;
}

.logout-button7 {
  padding: 0px !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-color: rgba(255, 255, 255, 0) !important;
  color: rgba(255, 255, 255, 0.5) !important;
}

.logout-button7:hover {
  padding: 0px !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: rgba(255, 255, 255, 0) !important;
  color: rgba(255, 255, 255, 0.5) !important;
}

.logout-button7:focus,
.logout-button7:active,
.logout-button7.active,
.open .dropdown-toggle.logout-button7 {
  padding: 0px !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: rgba(255, 255, 255, 0) !important;
  color: rgba(255, 255, 255, 0.5) !important;
}

.open .dropdown-toggle.logout-button7 {
  padding: 0px !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border-color: rgba(255, 255, 255, 0) !important;
  color: rgba(255, 255, 255, 0.5) !important;
}

.logout-button4 {
  font-sz: 6 !important;
  padding: 7px !important;
  background-color: #ffbf17 !important;
  border-color: #ffbf17 !important;
  border-radius: 5px !important;
  color: black !important;
}

.logout-button4:hover {
  background-color: #ffbf17 !important;
  color: black !important;
}

.logout-button4:focus,
.logout-button4:active,
.logout-button4.active,
.open .dropdown-toggle.logout-button {
  background-color: #ffbf17 !important;
  color: black !important;
}

.open .dropdown-toggle.logout-button4 {
  background-color: #ffbf17 !important;
  color: black !important;
}

.logout-button2 {
  padding: 10px !important;
  background-color: none !important;
  border-color: rgba(255, 255, 255, 0.6) !important;
  border-radius: 5px !important;
  color: rgba(255, 255, 255, 0.6) !important;
}

.logout-button2:hover {
  background-color: #202434 !important;
  color: white !important;
}

.logout-button2:focus,
.logout-button2:active,
.logout-button2.active,
.open .dropdown-toggle.logout-button2 {
  background-color: #202434 !important;
  color: white !important;
}

.open .dropdown-toggle.logout-button2 {
  background-color: #202434 !important;
  color: white !important;
}

.gli-button {
  padding: 5px;
  border-color: black !important;
  background-color: black !important;
  color: #797aae;
}

.gli-button:hover {
  background-color: #202434 !important;
  color: white !important;
}

.gli-button:focus,
.gli-button:active,
.gli-button.active,
.open .dropdown-toggle.gli-button {
  background-color: #202434 !important;
  color: white !important;
}

.open .dropdown-toggle.gli-button {
  background-color: #202434 !important;
  color: white !important;
}

.purchase-button {
  background-color: #3a446c !important;
  border-color: #3a446c !important;
}

.live-text {
  margin-bottom: 1rem;
}

.pulse {
  animation: pulse 0.3s;
  animation-iteration-count: 1;
}

.pulse-2 {
  animation: pulse-2 1.6s infinite;
}

.shake {
  animation: shake 8.72s ease;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  animation-iteration-count: infinite;
}

.play-button {
  border-style: none;
  font-weight: bold;
  color: black !important;
  background-image: linear-gradient(#f5bd1f, #fdca00);
  border-width: 0px !important;
  padding: 8px;
  border-radius: 39px;
}

.play-button:hover {
  font-weight: bold;
  background-image: linear-gradient(#cbaa17, #b69614);
  border-color: #f137a6 !important;
}

.play-button:focus,
.play-button:active,
.play-button.active,
.open .dropdown-toggle.play-button {
  font-weight: bold;
  color: black !important;
  background-image: linear-gradient(#cbaa17, #b69614);
}

.open .dropdown-toggle.play-button {
  background-color: #aca070 !important;
  color: black !important;
}

textarea:focus,
input:focus {
  outline: none !important;
}

@keyframes pulse-2 {
  0% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    transform: scale(1.2);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  80% {
    transform: scale(1.15);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes shake {
  70% {
    transform: translate(0, -0.5px);
  }
  71.78571% {
    transform: translate(0, 2px);
  }
  73.57143% {
    transform: translate(0, -2px);
  }
  75.35714% {
    transform: translate(0, 2px);
  }
  77.14286% {
    transform: translate(0, -2px);
  }
  78.92857% {
    transform: translate(0, 2px);
  }
  80.71429% {
    transform: translate(0, -2px);
  }
  82.50001% {
    transform: translate(0, 0px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.textarea {
  height: 120px;
}

.wager-color {
  color: #687298 !important;
}

.week-color {
  color: #02a377 !important;
}

.time-color {
  color: #16b78c !important;
}

.share-color {
  color: #b08926 !important;
}

.scored-checkmark {
  height: 20px !important;
  margin-top: 5%;
}

.rules-logo {
  height: 125px;
}

.wnj-logo {
  min-width: 300px !important;
  max-width: 300px !important;
}

.header-logo.active,
.header-logo:active,
.header-logo {
  height: 100px;
  padding-top: 5px;
  padding-bottom: 0px !important;
  margin-left: auto;
  margin-right: auto;
  border-style: none !important;
}

.four-logo {
  height: 70px;
  padding-top: 5px;
  padding-bottom: 5px !important;
  margin-left: auto;
  margin-right: auto;
  border-style: none !important;
}

.reg-logo {
  height: 100px;
  justify-content: center;
  border-style: none !important;
  margin-bottom: 20px;
}

.jumbo-container {
  position: relative;
  min-height: 100vh;
  padding: 25px 0;
}

.jumbo-footer {
  bottom: 0;
  height: 2rem;
  position: absolute;
  width: 100%;
}

.zlay-header {
  display: flex;
  justify-content: space-between !important;
  vertical-align: baseline !important;
  align-items: baseline !important;
  max-height: 70px;
}

.play-header {
  display: flex;
  justify-content: space-between !important;
  vertical-align: baseline !important;
  align-items: baseline !important;
  max-height: 70px;
}

.play-header1 {
  display: flex;
  justify-content: space-between !important;
  vertical-align: baseline !important;
  align-items: baseline !important;
  min-height: 30px !important;
  max-height: 30px !important;
}

.play-header-55 {
  display: inline-block !important;
  text-align: justify !important;
  vertical-align: top !important;
}

.play-header-3 {
  justify-content: right !important;
  vertical-align: baseline !important;
  display: inline-block !important;
  align-items: top !important; /* Vertical center alignment */
  justify-content: left !important; /* Horizontal center alignment */
}

.test-header {
  display: flex; /* ADDED */
  align-items: flex-end; /* ADDED */
  justify-content: space-between;
  width: 100%;
}

.spinner-border-lg {
  width: 4rem !important;
  height: 4rem !important;
}

.lobby-header {
  justify-content: center;
  vertical-align: middle !important;
  align-items: middle !important;
}

.share-header {
  display: flex;
  justify-content: left;
  vertical-align: bottom !important;
  align-items: bottom !important;
}

.share-header2 {
  display: flex;
  justify-content: left;
  vertical-align: baseline !important;
  align-items: baseline !important;
  max-height: 70px;
}

.user-header {
  display: flex;
  align-items: center !important;
  vertical-align: bottom !important;
  align-items: baseline !important;
}

.user-header-home {
  display: flex;
  justify-content: center;
  align-items: center !important;
  vertical-align: bottom !important;
}

.user-header31 {
  display: flex;
  justify-content: center;
  align-items: center !important;
  vertical-align: bottom !important;
}

.user-header-21 {
  display: flex;
  justify-content: right !important;
  vertical-align: baseline !important;
  align-items: baseline !important;
}

.gli-header {
  display: flex;
  align-items: center !important;
  vertical-align: text-bottom !important;
}

.leaderboard-header {
  display: flex;
  align-items: center;
  vertical-align: text-bottom !important;
  justify-content: space-between;
}

.leaderboard2-header {
  display: flex;
  align-items: center;
  vertical-align: text-bottom !important;
  justify-content: center;
}

.align-vertical {
  align-items: center;
}

.vertical-align {
  vertical-align: text-top;
}

.wager-header {
  display: flex;
  justify-content: center;
}

.share-with-friends {
  color: #00cc8a;
}

.wager-amount {
  color: #f037a5;
}

.prop-header {
  color: #fbfcfc;
  display: flex;
  justify-content: left;
}

.floating {
  vertical-align: middle;
}

.bg-secondary {
  background-color: #333333 !important;
}

.maps-container {
  width: 100%;
  height: 200px;
}

.preview-image {
  height: 165px;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.button {
  background-color: #333366;
}

.game-insights-bars {
  height: 15px !important;
  min-width: 100% !important;
  max-width: 100% !important;
  border-color: #484c5c !important;
  border-style: solid !important;
  border-width: 1px !important;
  background-color: #484c5c !important;
  border-radius: 5px !important;
}

hr {
  border: none;
  border-top: 0.25px solid #28292e;
  color: #28292e;
  height: 0.25px;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.cr {
  border: none;
  border-top: 1px dotted #28292e;
  color: #28292e;
  height: 1px;
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

body {
  background-color: #000000 !important;
  /*background: linear-gradient(#233160, #1c274d);*/
}

.dropdown-menu {
  background-color: black !important;
  color: #71717e !important;
  border-radius: 10px;
  min-height: 10px !important;
}

a {
  color: white;
  text-decoration: none !important;
  font-weight: bold;
  background-color: transparent;
}

.list-group-item {
  background-color: #344170;
  border-radius: 0;
  color: #fff;
}

.tbody {
  background-color: transparent;
  border-radius: 1;
  border-color: red;
  color: red;
}

.title-gray {
  color: white;
  text-decoration: none;
  background-color: transparent;
}

input[type="range"] {
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-thumb {
  background: #f5f5f5;
  cursor: pointer;
  -webkit-appearance: none;
  border-color: #f5f5f5 !important;
  color: #ffbf17 !important;
  width: 18px !important;
  height: 30px !important;
  margin-top: -15px;
  border-radius: 5px !important;
  background-color: #f5f5f5;
  box-shadow: 1px 1px 2px #000000;
}

input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0px 0px 5px 0px rgb(254, 224, 96, 0);
}

input[type="range"]::-webkit-slider-runnable-track {
  background-color: #32333a;
  color: white;
  height: 5px !important;
  border-radius: 3px !important;
  border: 0px solid #484c5c;
}

.modal-image {
  max-width: 100%;
  padding-top: 0px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: black;
  border-width: 1px;
}

.upsell-header {
  max-height: 0px;
  background-color: #000000;
  color: black;
  border-color: #000000;
  border-width: 5px !important;
}

.upsell-body {
  background-color: #000000;
  color: black;
  border-color: white !important;
  border-width: 10px !important;
  border-radius: 1px !important;
}

.jumbo-toast-container {
  margin-bottom: 55px !important;
}

.jumbo-toast {
  background-color: #1e2126 !important;
  color: white !important;
}

.Toastify__close-button {
  color: white !important;
}

.gold-text {
  background-color: black;
}

.badge-text {
  color: #80dfc5 !important;
}

.pink-text {
  color: #15d6ff !important;
}

.ninja-black {
  color: #8c8c9e !important;
  outline: black !important;
}

.red-text {
  color: #ff0428 !important;
}

.orange-text {
  color: orange !important;
}

.orange-text2 {
  color: #ff8140 !important;
}

.blue-text2 {
  color: #40beff !important;
}

.red-text-half {
  color: #a25c58 !important;
}

.fire-text {
  color: #ff8c2d !important;
}

.red-text-2 {
  color: #ee8990 !important;
}

.red-text-3 {
  color: #f7dee0 !important;
}

table tr:nth-child(odd) td th {
  background-color: #000000;
  border-color: #323232 !important;
  vertical-align: baseline !important;
}
table tr:nth-child(even) td th {
  background-color: #000000;
  border-color: #323232 !important;
  vertical-align: baseline !important;
}

table td {
  border-top: 1px solid #6f6f7f !important;
}

.balance tr:nth-child(odd) td {
  border-color: #000000 !important;
  color: white;
  vertical-align: baseline !important;
}

.balance tr:nth-child(even) td {
  border-color: #000000 !important;
  color: white;
  vertical-align: baseline !important;
}

.balance td {
  border-color: #1a213a !important;
  color: white;
  vertical-align: baseline !important;
}

/*.winner-rank tr:nth-child(1) td:nth-child(1) {
  width: 10px !important;
  height: 10px !important;
  padding: 5px 5px 0 0 !important;
  border-radius: 39px !important;
  font-size: 20px !important;
  line-height: 1.1 !important;
  background-color: #009bf0;
  border-color: #db1212;
  color: white;
  text-align: center;
}
.winner-rank tr:nth-child(2) td:nth-child(1) {
  width: 10px !important;
  height: 10px !important;
  padding: 5px 5px 0 0 !important;
  border-radius: 39px !important;
  font-size: 20px !important;
  background-color: #db1212;
  border-color: #db1212;
  color: white;
  text-align: center;
}
.winner-rank tr:nth-child(3) td:nth-child(1) {
  width: 10px !important;
  height: 10px !important;
  padding: 5px 5px 0 0 !important;
  border-radius: 39px !important;
  font-size: 20px !important;
  background-color: #00f09d;
  border-color: #00f09d;
  color: white;
  text-align: center;
}
.winner-rank tr:nth-child(4) td:nth-child(1) {
  width: 10px !important;
  height: 10px !important;
  padding: 5px 5px 0 0 !important;
  border-radius: 39px !important;
  font-size: 20px !important;
  background-color: #00f09d;
  border-color: #00f09d;
  color: white;
  text-align: center;
}
.winner-rank tr:nth-child(5) td:nth-child(1) {
  width: 10px !important;
  height: 10px !important;
  padding: 5px 5px 0 0 !important;
  border-radius: 39px !important;
  font-size: 20px !important;
  line-height: 1.1 !important;
  background-color: #00f09d;
  border-color: #00f09d;
  color: white;
  text-align: center;
}
*/
.winner tr:nth-child(1) td {
  color: #0ae2a1 !important;
  font-weight: bold;
}

.winner tr:nth-child(2) td {
  color: #0ae2a1 !important;
  font-weight: bold;
}

.winner tr:nth-child(3) td {
  color: #0ae2a1 !important;
  font-weight: bold;
}

.winner tr:nth-child(4) td {
  color: #0ae2a1 !important;
  font-weight: bold;
}

.winner tr:nth-child(5) td {
  color: #0ae2a1 !important;
  font-weight: bold;
}

.leaderboard-content {
  min-width: 30px !important;
}

.leaderboard tr td {
  padding: 8px 0px 8px 0px !important;
}

.leaderboard tr:nth-child(odd) td {
  border-color: #28292e !important;
  color: white;
  vertical-align: baseline !important;
}

.leaderboard tr:nth-child(even) td {
  border-color: #28292e !important;
  color: white;
  vertical-align: baseline !important;
}

.leaderboard td {
  border-color: #28292e !important;
  color: white;
  vertical-align: baseline !important;
}

.balance-prob {
  color: #8591c0 !important;
}

.modal-dialog {
  padding-top: 10%;
}

.modal-dialog-custom {
  padding-top: 10%;
  border-color: #000000 !important;
  border-width: 1px !important;
}

.modal-backdrop {
  opacity: 0.85 !important;
}

.table thead th {
  border-color: #28282e !important;
  color: white;
  vertical-align: baseline;
}

.btn-circle.btn-xl {
  width: 48px;
  height: 48px;
  padding: 1px 0px 0 0;
  border-radius: 35px;
  font-size: 20px;
  line-height: 1.1;
  background-color: #db1212;
  border-color: #db1212;
  color: white;
}

.btn-circle.btn-xl:hover {
  background-color: #a21e6e !important;
  color: white !important;
  border-color: #a21e6e !important;
}

.btn-circle.btn-xl:focus,
.btn-circle.btn-xl:active,
.btn-circle.btn-xl.active,
.btn-circle.btn-xl .dropdown-toggle.btn-circle.btn-xl {
  background-color: #a21e6e !important;
  color: white !important;
  border-color: #a21e6e !important;
}

.open .dropdown-toggle.btn-circle.btn-xl {
  background-color: #a21e6e !important;
  color: white !important;
  border-color: #a21e6e !important;
}

.btn-circle-done.btn-xl {
  width: 48px;
  height: 48px;
  padding: 12px 0px 0 0;
  border-radius: 35px;
  font-size: 20px;
  line-height: 1.1;
  background-color: black;
  border-color: #323232;
  color: #db1212;
}

.btn-circle-done.btn-xl:hover {
  background-color: #a21e6e !important;
  color: white !important;
  border-color: #a21e6e !important;
}

.btn-circle-done.btn-xl:focus,
.btn-circle-done.btn-xl:active,
.btn-circle-done.btn-xl.active,
.btn-circle-done.btn-xl.dropdown-toggle.btn-circle.btn-xl {
  background-color: #a21e6e !important;
  color: white !important;
  border-color: #a21e6e !important;
}

.open .dropdown-toggle.btn-circle-done.btn-xl {
  background-color: #a21e6e !important;
  color: white !important;
  border-color: #a21e6e !important;
}

.pro {
  list-style: none;
}

.pro li:before {
  content: "✓";
  color: #f5bd1f;
}

.container-flex {
  display: flex; /* establish flex container */
  flex-direction: column; /* stack flex items vertically */
  justify-content: center; /* center items vertically, in this case */
  align-items: center; /* center items horizontally, in this case */
  height: 100px;
  border: 1px solid black;
}

.box {
  width: 100px;
  margin: 5px;
  text-align: center;
}

.blur-image {
  /* Add the blur effect */
  filter: blur(4px);
  -webkit-filter: blur(4px);
  height: 100%;

  width: 100%;
}

.blue-image {
  color: #985cf0;
}

.green-image {
  color: #aeaec4;
}

.orange-image {
  color: #aeaec4;
}

.neumorphic {
  box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

.neumorphic:active,
.neumorphic:focus,
.neumorphic:hover,
.neumorphic.active:hover,
.neumorphic.active:focus,
.neumorphic.active.dropdown-toggle {
  box-shadow: inset 6px 6px 10px 0 rgba(0, 0, 0, 0.3),
    inset -6px -6px 10px 0 rgba(255, 255, 255, 0.2);
}

.variation1 {
  background: linear-gradient(
    -30deg,
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.99)
  ) !important;
}

.greeny-0 {
  color: #c5d9d3 !important;
}

.greeny {
  color: #89c2b1 !important;
}

.greeny2 {
  color: #395a2d !important;
}

.greeny3 {
  color: #8aabab !important;
}

.slam {
  animation: slam 1s;
  animation-timing-function: ease-in;
  color: #c41818;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

.dow table tr td th {
  background-color: black !important;
  table-layout: fixed !important;
  border-color: rgba(255, 255, 255, 0.2) !important;
  height: 5px !important;
}

.leaderboard-highlight-user {
  background-color: #592c0c !important;
  color: white !important;
  font-size: largest !important;
}

.add-button {
  color: #56ff7f !important;
}

.header-font-dob {
  font-size: 4.5rem !important;
  font-weight: bold;
  margin-bottom: 0px !important;
  margin-top: -20px !important;
}

.secondary-font-dob {
  font-size: 1.2rem !important;
  font-weight: bold;
}

.upcoming-games .jumbo-card-deck .jumbo-card {
  float: left;
  display: block;
}

.jumbo-card-deck.scrollable {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  justify-content: flex-start;
  -webkit-overflow-scrolling: touch;
}

.scroll-control {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  width: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  top: 0;
  bottom: 0;
}
.scroll-control:hover {
  cursor: pointer;
}
.scroll-control > * {
  /* prevent text from being selected when clicking */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  color: rgba(0, 0, 0, 0);
}
.scroll-right {
  right: 0;
}
.scroll-left {
  left: 0;
}
.jumbo-install-prompt .toast-header button:not(:disabled),
.jumbo-install-prompt .toast-header [type="button"]:not(:disabled),
.jumbo-install-prompt .toast-header [type="reset"]:not(:disabled),
.jumbo-install-prompt .toast-header [type="submit"]:not(:disabled) {
  color: inherit;
}
